import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <main className="vh-100 flex justify-center items-center">
        <h1 className="text-white">
            PAGE NOT FOUND
        </h1>
    </main>
  </Layout>
)

export default NotFoundPage
